<template>
  <RenderCacheable
    :max-age="86400"
    :cache-key="[getUserLanguage.name, productHash].join('--')"
  >
    <SummaryWrapper
      :product="product"
      :navigation-height="navigationHeight"
      :configurations="configurations"
      :reviews="reviews"
    />
  </RenderCacheable>
</template>

<script setup>
import { Product } from "~/models/product.model";
import SummaryWrapper from "~/modules/product/ui/pages/Summary/SummaryWrapper.vue";
import { useLanguageStore } from "~/store/languageStore";

const props = defineProps({
  product: { type: Product, required: true },
  configurations: { type: [Array, null], required: false },
  reviews: { type: Object, required: false },
  navigationHeight: { type: Number, required: false },
  productHash: { type: [String, null], required: true },
});

const languageStore = useLanguageStore();
const { getUserLanguage } = storeToRefs(languageStore);

const patternSeo = {
  useCustomMeta: true,
  metaTitle: {
    postfix: () => _T("@Buy"),
  },
  metaDescription: {
    postfix: () =>
      _T(
        "@buy at a competitive price from the manufacturer, delivery, warranty",
      ),
  },
  metaKeywords: {},
};

configureProductSeo(props.product, patternSeo);
</script>
